//Localhost
// export const apiUrlUser ='http://localhost:9001/'
// export const apiUrlEvent ='http://localhost:8082/'

//adm INT
//export const apiUrlUser ='https://beaconapp-int.spie.com/'
//export const apiUrlEvent ='https://beaconapp-int.spie.com/'


//adm Prod
export const apiUrlUser ='https://beaconapp.spie.com/'
export const apiUrlEvent ='https://beaconapp.spie.com/'
